import "core-js/modules/es6.string.link";
import "core-js/modules/es6.object.assign";
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Link, SEO } from 'components';
import { useDetectSSR, useNavIntro } from 'hooks';
import { ActiveArrow } from 'svgs';
import { className } from 'utils';
import styles from './styles/404.module.scss';

var NotFoundPage = function NotFoundPage(_ref) {
  var seo = _ref.data.page.seo,
      pathname = _ref.location.pathname;
  useNavIntro();
  var isSSR = useDetectSSR();

  var _useState = useState(true),
      isBackLinkActive = _useState[0],
      setIsBackLinkActive = _useState[1];
  /**
   * Creates props object for adding mouse over and focus
   * event handlers to set active link.
   *
   * @param {number} isActive whether to set the link to
   * active
   * @returns {object} the props object to spread on a
   * React component
   */


  var addEventListeners = function addEventListeners(isActive) {
    var updateActiveLink = function updateActiveLink() {
      return setIsBackLinkActive(isActive);
    };

    return {
      onFocus: updateActiveLink,
      onMouseOver: updateActiveLink
    };
  };
  /**
   * Traverses browser history to go back to the
   * previous page.
   */


  var goBack = function goBack() {
    window.history.back();
  };

  return React.createElement(Grid, {
    className: styles.page
  }, React.createElement(SEO, Object.assign({}, seo, {
    pathname: pathname
  })), React.createElement("header", {
    className: styles.header
  }, React.createElement("h1", {
    className: styles.headerText
  }, "Not Found"), React.createElement("p", {
    className: styles.headerSubtext
  }, "The page you\u2019re trying to reach does not exist.")), React.createElement("div", {
    className: styles.links
  }, !isSSR && React.createElement("button", Object.assign({
    onClick: goBack
  }, addEventListeners(true), className(styles.link, isBackLinkActive && styles.active)), "Previous page ", React.createElement(ActiveArrow, {
    className: styles.activeArrow
  })), React.createElement(Link, Object.assign({}, addEventListeners(false), className(styles.link, !isBackLinkActive && styles.active), {
    to: "/"
  }), "Go home ", React.createElement(ActiveArrow, {
    className: styles.activeArrow
  }))));
};

NotFoundPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      seo: PropTypes.object.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};
export default NotFoundPage;
export var query = "3784663099";